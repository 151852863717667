import React from 'react'
import Helmet from 'react-helmet'
import LangSelector from '../components/lang-selector'
import Layout from '../components/layout'
import Header from '../components/header'
import Section from '../components/section'
import data from './data'

const IndexEn = () => (
  <Layout>
    <Helmet>
      <meta name='description' content='iOS mobile developer. Lives in Brazil and work remotly since 2015.' />
      <meta name='keywords' content='apps, programming, developer, android, ios, app, pythonm, java, swift, kotlin, obj-c, react, native, ruby, rails, flutter, gdg' />
      <html lang='en' />
    </Helmet>
    <LangSelector />
    <Header email={data.email} introArray={data.intro_en} />
    <Section title='social networks & links' links={data.links} />
    <Section title='tech' tags={data.tools} />
    <Section title='talks' list={data.talks} />
  </Layout>
)

export default IndexEn
